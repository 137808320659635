import { usePublicMenusForLocation } from '@Pacific-Web-Services/component-library'
import { useLocation } from 'react-router-dom'
import { useWayfarerAccount } from '../hooks/useWayfarerAccount'

export default function useMenus() {
  const { data: account } = useWayfarerAccount()
  const locationId = account?.locations[0].id!
  const { data } = usePublicMenusForLocation(locationId, {
    enabled: !!locationId,
  })
  const { pathname } = useLocation()
  let menus = data?.filter((menu) => menu.id !== 9 && menu.id !== 15 && menu.id !== 14)
  if (pathname === '/tacos') {
    menus = data?.filter((menu) => menu.id === 9 || menu.id === 15)
  }
  if (pathname === '/easter') {
    menus = data?.filter((menu) => menu.id === 14)
  }
  if (pathname === '/checkout') {
    menus = data
  }
  return menus
}
